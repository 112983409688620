import { EmailInput } from "../../fragments/emailInputFieldNoComponents/emailInputField"
import JavascriptChallenge from "../../javascript_challenge"
import { Exactag } from "../exactag"
import { hasMobileUserAgent } from "../utils"
import "./loginPageNoComponents.scss"
import { tracking } from "@otto-ec/tracking-bct"
import { querySelectorNonNull } from "../../document"
import PasswordInputFieldNoComponents from "../../fragments/passwordInputFieldNoComponents/passwordInputFieldNoComponents"
import { onAllPreloadScriptsLoaded } from "../../utils"
import {
    hasPostalCode,
    PostalCodeInputFieldNoComponents,
} from "../../fragments/postalCodeInputFieldNoComponents/postalCodeInputFieldNoComponents"

class LoginPageNoComponents {
    private readonly inputEmailValidation: EmailInput | undefined
    private readonly passwordComponent: PasswordInputFieldNoComponents
    private readonly postalCodeComponent: PostalCodeInputFieldNoComponents | undefined
    private readonly loginFlow: string
    private trackTogglePasswordShowClick = () => {
        tracking
            .submitEvent({
                user_LoginActivity: ["click_ShowPassword"],
                user_LoginFlow: [this.loginFlow],
            })
            .catch((e: unknown) => {
                console.error(e)
            })
    }
    private trackTogglePasswordHideClick = () => {
        tracking
            .submitEvent({
                user_LoginActivity: ["click_HidePassword"],
                user_LoginFlow: [this.loginFlow],
            })
            .catch((e: unknown) => {
                console.error(e)
            })
    }

    constructor(
        document: Document,
        window: Window,
        private readonly exactag = new Exactag(),
    ) {
        if (document.querySelector(".fti_js_login_welcomeBack")) {
            this.loginFlow = "SoftLoggedOut"
        } else {
            this.loginFlow = "Login"
            this.inputEmailValidation = new EmailInput(document)
            this.inputEmailValidation.registerEventListeners()
        }
        const rememberMeCheckbox: HTMLInputElement | null = document.querySelector(".fti_js_rememberMeCheckbox")
        if (rememberMeCheckbox) {
            this.setRememberMeForMobileDevices(window, rememberMeCheckbox)
        }

        this.passwordComponent = new PasswordInputFieldNoComponents(
            document,
            this.trackTogglePasswordShowClick,
            this.trackTogglePasswordHideClick,
        )
        this.passwordComponent.registerEventListeners()
        this.addChallenge(document)
        if (hasPostalCode(document)) {
            this.postalCodeComponent = new PostalCodeInputFieldNoComponents(document)
            this.postalCodeComponent.registerEventListeners()
        }
        this.setBlockedTimer(document)
        this.exactag.registerExactag()
    }

    private addChallenge(document: Document) {
        const challengeValue = querySelectorNonNull(document, ".fti_challenge-value").getAttribute("data-value")
        if (challengeValue) {
            const formElement: HTMLFormElement = querySelectorNonNull(document, ".fti_login_loginFormContainer")
            const javascriptChallenge = new JavascriptChallenge(formElement)
            javascriptChallenge.setHiddenChallengeValue(challengeValue)
        }
    }

    private setBlockedTimer(document: Document) {
        const blockedTimer = document.querySelector("#fti_js_blocked_counter")
        if (blockedTimer != null) {
            const display = blockedTimer as HTMLElement
            const unblockTimeSeconds = Math.floor(new Date().getTime() / 1000 + 3600)
            const interval = setInterval(() => {
                const remainingBlockedSeconds = this.calculateSecondsFromNowUntil(unblockTimeSeconds)
                if (remainingBlockedSeconds <= 0) {
                    display.innerHTML = "00:00"
                    clearInterval(interval)
                } else {
                    display.innerHTML = this.formatCountdown(remainingBlockedSeconds)
                }
            }, 1000)
        }
    }

    private calculateSecondsFromNowUntil(untilSeconds: number) {
        return Math.floor(untilSeconds - new Date().getTime() / 1000)
    }

    private formatCountdown(timer: number) {
        return this.formatCountdownPart(Math.floor(timer / 60)) + ":" + this.formatCountdownPart(Math.floor(timer % 60))
    }

    private formatCountdownPart(timePart: number) {
        return (timePart < 10 ? `0${timePart.toString()}` : timePart) as string
    }

    private setRememberMeForMobileDevices(window: Window, rememberMeCheckbox: HTMLInputElement): void {
        const isCustomerSelection = rememberMeCheckbox.hasAttribute("data-js-customerSelection")
        if (isCustomerSelection) {
            return
        }
        const isMobileUserAgent = hasMobileUserAgent(window.navigator.userAgent)
        const isLandscape = window.matchMedia("(orientation: landscape)").matches || false
        const isPortrait = window.matchMedia("(orientation: portrait)").matches || false
        const isMobileSize = (isPortrait && window.innerWidth < 768) || (isLandscape && window.innerWidth < 1024)
        const isTouchDevice = window.navigator.maxTouchPoints > 0 || window.matchMedia("(pointer: coarse)").matches || false
        const isMobileDevice = isMobileUserAgent || (isMobileSize && isTouchDevice)
        if (isMobileDevice) {
            rememberMeCheckbox.checked = isMobileDevice
        }
        tracking.submitMerge({
            user_LoginRememberMePreselected: [isMobileDevice.toString()],
        })
    }
}

onAllPreloadScriptsLoaded(() => new LoginPageNoComponents(document, window))

export default LoginPageNoComponents
