import { fetchPostalCodeInputValidation } from "../api"
import { querySelectorNonNull } from "../../document"

const postalCodeSelector = "#fti_input_postalCode"

class PostalCodeInputFieldNoComponents {
    private readonly $postalcodeInputElement: HTMLInputElement
    private readonly $inputHintElement: HTMLElement
    private onErrorStatusChangeCallback?: () => void
    private onErrorStatusChangeCallbackCallee?: () => void
    private hasError = false

    constructor(document: Document) {
        this.$inputHintElement = querySelectorNonNull(document, ".fti_js_postalCode_input_hint")
        this.$postalcodeInputElement = querySelectorNonNull(document, postalCodeSelector)
    }

    registerEventListeners() {
        this.$postalcodeInputElement.addEventListener("focusout", this.onFocusOut)
    }

    private onFocusOut = () => {
        this.$postalcodeInputElement.removeEventListener("input", this.onInput)
        this.$postalcodeInputElement.addEventListener("input", this.onInput)
        this.validateInput().catch((e: unknown) => {
            console.error(e)
        })
    }

    private onInput = () => {
        this.validateInput().catch((e: unknown) => {
            console.error(e)
        })
    }

    private async validateInput() {
        try {
            const responseData = await fetchPostalCodeInputValidation(this.$postalcodeInputElement.value)
            const errorMessage = responseData.errorMessage
            this.hasError = errorMessage != null && errorMessage != ""
            if (this.hasError) {
                this.showErrorHint(errorMessage)
            } else {
                this.hideErrorHint()
            }

            this.onErrorStatusChange()
        } catch (exception) {
            console.error(exception)
        }
    }

    private showErrorHint(message?: string) {
        if (message != null) {
            this.$inputHintElement.innerText = message
        }

        const parentClassList = this.$inputHintElement.parentElement?.classList
        parentClassList?.remove("pl_input--hint")
        parentClassList?.add("pl_input--invalid")
        this.$inputHintElement.style.display = "block"
    }

    private hideErrorHint() {
        const hasHintClass = this.$inputHintElement.parentElement?.classList.contains("pl_input--invalid")
        if (hasHintClass) {
            this.$inputHintElement.style.display = "none"
            this.$inputHintElement.parentElement?.classList.remove("pl_input--invalid")
        }
    }

    private onErrorStatusChange() {
        this.onErrorStatusChangeCallback?.call(this, !this.hasError, this.onErrorStatusChangeCallbackCallee)
    }
}

function hasPostalCode(document: Document) {
    return document.querySelector(postalCodeSelector) != null
}

export { PostalCodeInputFieldNoComponents, hasPostalCode }
